$(document).foundation();

$(window).scroll(function () {
    var scroll = $(window).scrollTop();
    var offsetOrbit = $('.orbit-wrapper').offset().top;
    var heightOrbit = $('.orbit-wrapper').height() - 160;
    if (scroll > offsetOrbit + heightOrbit) {
        $('.navigation-sticky').removeClass('deshrink');
        $('.navigation-sticky').addClass('shrink');
    } else {
        $('.navigation-sticky').removeClass('shrink');
        $('.navigation-sticky').addClass('deshrink');
    }
});